<template>
    <div>
        <div class="row">
            <h3 class="col">Order #123456</h3>
            <div class="col fl-x-tr">
                <btn color="primary">Delivered</btn>
                <btn color="danger" class="ml-1">Cancel</btn>
            </div>
        </div>
        <div class="mt-3 fl-eq">
            <div class="card shadow">
                <h6>Customer details</h6>
            </div>
            <div class="card shadow">
                <h6>Order details</h6>
            </div>
            <div class="card shadow">
                <h6>Delivery address</h6>
            </div>
        </div>
        <div class="card mt-3 shadow">
            <simple-table :fields="fields" :data="data"></simple-table>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'orderDetails',
    props : {
        data : {
            type : Array
        }
    },
    data () {
        return {
            fields : [
                {
                    name       : '',
                    sortField  : '',
                    title      : 'SL NO.',
                    titleClass : ''
                },
                {
                    name       : 'e',
                    sortField  : '',
                    title      : 'Product',
                    titleClass : ''
                },
                {
                    name       : '',
                    sortField  : 'type',
                    title      : '',
                    titleClass : ''
                },
                {
                    name       : '',
                    sortField  : '',
                    title      : 'Quantity',
                    titleClass : ''
                },
                {
                    name       : '',
                    sortField  : '',
                    title      : 'Rate',
                    titleClass : ''
                },
                {
                    name       : '',
                    sortField  : '',
                    title      : 'Total',
                    titleClass : ''
                }
            ]
        };
    }
};
</script>

<style scoped>
.shadow {
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
}
</style>
