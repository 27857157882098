<template>
    <div>
        <div class="row">
            <div class="col-md-2">
                <div class="row mb-3">
                    <h3>Orders List</h3>
                </div>
                <div>
                    <name-card v-for="index in 10" :key="index"></name-card>
                </div>
            </div>
            <div class="col-md-10">
                <order-details></order-details>
            </div>
        </div>
    </div>
</template>

<script>
import NameCard from '@/views/orders/orderComponents/nameCard';
import OrderDetails from '@/views/orders/orderComponents/orderDetails';

export default {
    name       : 'orders',
    components : { OrderDetails, NameCard }
};
</script>

<style scoped>

</style>
