<template>
    <div class="card shadow">
        <p>Order</p>
    </div>

</template>

<script>
export default {
    name : 'nameCard'
};
</script>

<style scoped>
.shadow {
    box-shadow: 0 4px 24px 0 rgb(0 0 0 / 20%);
}
</style>
